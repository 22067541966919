import './App.css';
import React, { useEffect } from "react";
import axios from "axios";
const api = axios.create({
  baseURL: process.env.REACT_APP_HUB_API_URL,
});

function App() {

  const redirect = async(code) => {
    const { data } = await api.get(`redirect?code=${code}`);
    window.location.assign(data.url)
  }

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    const { code } = params
    if(code){
      redirect(code)
    }
  }, [])

  return (
    <div className="App">
      <header className="App-header">
        <img src={"https://petlandbrasil.com.br/wp-content/themes/pullup-theme/assets/img/logo_petlandbr.png"} className="App-logo" alt="logo" />
        <p>
          Redirecionando
        </p>
      </header>
    </div>
  );
}

export default App;
